import React from 'react'
import fondo from '../../img/Fondo.jpg';
import '../../styles/Clients.css'


export const ImageBackground = () => {
  return (
    <img src={fondo} alt="" className='img-fondo' />
    // <div class="grid-block" ></div>
  )
}
