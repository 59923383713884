import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import "../styles/Login.css"
import "../App.css"
import Loader from "../components/Loader"
import logo from '../img/Logo-spotuno.png';

export const Login = ({renderNavBarDealers}) => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('')
    const [pass, setPass] = useState('')
    const [notifyLogin, setNotifyLogin] = useState('')
    const [showNotifyDanger, setShowNotifyDanger] = useState(false)
    const [showNotifySuccess, setShowNotifySuccess] = useState(false)
    const [loader, setLoader] = useState(false)

    const currentPathLocal = window.location.pathname;

    useEffect(() => {
        const login = localStorage.getItem('login');
        const name = localStorage.getItem('name');
        const lastname = localStorage.getItem('lastname');
        const user_id = localStorage.getItem('user_id');

        if (login !== null && name !== null && lastname !== null && user_id !== null) {
            navigate("/home")
        } else {
            // console.log('La clave no existe en el Local Storage.');
        }

        renderNavBarDealers(currentPathLocal);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowNotifyDanger(false)
        setShowNotifySuccess(false)

        let data = { username, pass };
        if (Object.values(data).includes('')) {
            setNotifyLogin("Completa todos los campos del formulario.");
            setShowNotifyDanger(true)
            setShowNotifySuccess(false)
            setLoader(false)

            return false;
        }

        setLoader(true)

        try {
            const response = await fetch('https://apps-ws.spot1.mx/login-pos', {
                // const response = await fetch('https://apps-ws-test.spot1.mx/login-pos', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username,
                    password: pass
                })
            })

            const responseJson = await response.json()

            if (response.status == 200) {
                setNotifyLogin("Credenciales correctas!!!");
                setShowNotifyDanger(false)
                setShowNotifySuccess(true)
                setLoader(false)
                console.log(responseJson, " | RESPONSE JSON |")
                localStorage.setItem("login", true);
                localStorage.setItem("name", responseJson.name);
                localStorage.setItem("lastname", responseJson.lastname);
                localStorage.setItem("user_id", responseJson.user_id);
                navigate("/home")
            } else {
                setNotifyLogin("Usuario o contraseña incorrectos, pide apoyo a Soporte Técnico.");
                setShowNotifyDanger(true)
                setShowNotifySuccess(false)
                setLoader(false)
            }
        } catch (error) {
            console.log(error)
            setNotifyLogin(error);
            setShowNotifyDanger(true)
        }
    }

    return (
        <div className="container-pages">
            <div className="col-12-cust">
                <div className="container-center">
                    <form className="auth-form-container" onSubmit={handleSubmit}>
                        <img src={logo} className="App-logo" alt="logo" />
                        <p className="title-form-login">
                            Iniciar Sesión
                        </p>
                        <label className="label-form-login" htmlFor="username">Usuario: </label>
                        <input className="input-form-login" type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" id="username" name="username" />

                        <label className="label-form-login" htmlFor="password">Contraseña: </label>
                        <input className="input-form-login" type="password" value={pass} onChange={(e) => setPass(e.target.value)} placeholder="********" id="password" name="password" />


                        {showNotifyDanger && (
                            <label className="label-danger">{notifyLogin}</label>
                        )}

                        {showNotifySuccess && (
                            <label className="label-success">{notifyLogin}</label>
                        )}

                        {loader && (
                            <Loader title={"Cargando..."}/>
                        )}

                        <button className="btn-login">INGRESAR</button>
                    </form>
                </div>
            </div>
        </div>
    )
}