import React from 'react'
import { Link } from 'react-router-dom'

export const ItemCarousel = ({ date, dn, rate, user_email, number_id }) => {
    return (
        <div style={{ marginRight: '20rem', marginLeft: '20rem', }}>
            <div className="card">
                <div className="head-card">
                    <h2>Linea #1</h2>
                </div>
                <div className="card-body">
                    <p>Activado desde:</p>
                    <p className='prequest'>{date}</p>
                    <p>Paquete:</p>
                    <p className='prequest'>{rate}</p>
                    <p>Número:</p>
                    <p className='prequest'>{dn}</p>
                    <p>Correo electronico:</p>
                    <p className='prequest'>{user_email}</p>
                </div>
                <div className="card-footer">
                    <Link className="btn-navigationConsumo btn_info-navigation" to={`/uf?number=${dn}`}>

                        {/* <TbRecharging /> */}
                        <span>Consumos</span>
                    </Link>
                </div>
            </div>

        </div>
    )
}
