import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ItemCarousel } from '../components';

export const HomeClients = ({renderNavBarDealers}) => {
  const [devices, setDevices] = useState([])
    const currentPathLocal = window.location.pathname;

  useEffect(() => {
    renderNavBarDealers(currentPathLocal);
    const clientId = localStorage.getItem('client_id');
    const devicesClient = localStorage.getItem('devices');
    const devicesJson = JSON.parse(devicesClient)
    console.log(devicesJson)
    setDevices(devicesJson)
  }, [])
  return (
    <>
        <div className="title-uf">
          <div className="">
            <h1 style={{color:'#01669c'}}>MIS LÍNEAS</h1>
          </div>
          <div className="">
            <button className='btn btn-success'>Agregar linea</button>
          </div>
        </div>
        <div>
          {
            devices.length === 0 ? <h1>Aún no cuenta con dispositivos</h1> :
              <Carousel>
                {
                  devices.map(function(device){
                    return(
                        <div >
                          <ItemCarousel key={device.id} number_id={device.id} date={device.created_at} dn={device.number} user_email={device.user_email} rate={device.rate}/>              
                        </div>
                    )
                  })
                  
                }
              </Carousel>
          }
        </div>
    </>
  )
}
