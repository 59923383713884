import React, { useState, useEffect } from "react"
import "../styles/Generic.css"
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal'
import logo from '../img/Logo-spotuno.png';
import Swal from 'sweetalert2';
import Loader from "../components/Loader"
import Recharge from '../components/Modals/Dealers/Recharge'
import NewLine from '../components/Modals/Dealers/NewLine'

Modal.setAppElement('#root');

export const Home = ({ logged, setLogged, user_name, user_lastname, setUsername, setUserLastname, renderNavBarDealers }) => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false)
    const [userID, setUserID] = useState(false)
    const [loaderRecharge, setLoaderRecharge] = useState(false)
    const [msisdn, setMsisdn] = useState(false)
    const [msisdnConfirm, setMsisdnConfirm] = useState(false)
    const [primaryOffering, setPrimaryOffering] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [accessToken, setAccessToken] = useState('');

    // ACTIVATIONS VARIABLES
    const [typeSale, setTypeSale] = useState('0');
    const [portIn, setPortIn] = useState(0);
    const [imei, setImei] = useState('');
    const [respImei, setRespImei] = useState({});
    const [modalSaleSim, setModalSaleSim] = useState(false);

    const currentPathLocal = window.location.pathname;

    useEffect(() => {
        const login = localStorage.getItem('login');
        const name = localStorage.getItem('name');
        const lastname = localStorage.getItem('lastname');
        const user_id = localStorage.getItem('user_id');

        if (login == null && name == null && lastname == null && user_id == null) {
            console.log('Sesion NO iniciada...');
            navigate("/login")

        } else {
            console.log('Sesion iniciada, sincronizando datos del usuario... | Home.jsx LINE 34 |');
            setLogged(true)
            setUsername(name)
            setUserLastname(lastname)
            setUserID(user_id);
            getDataUserAPI();
            generateTokenAltan();
            renderNavBarDealers(currentPathLocal);
        }
    }, []);

    const getDataUserAPI = async () => {
        const user_id = localStorage.getItem('user_id');
        setLoader(true)
        try {
            const response = await fetch('https://apps-ws.spot1.mx/get-data-user-pos', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: user_id
                })
            })

            const responseJson = await response.json()

            if (response.status == 200) {
                const { http_code, message, user_id, name, lastname, saldo, salesforce } = responseJson

                localStorage.setItem("name", name);
                localStorage.setItem("lastname", lastname);
                localStorage.setItem("saldo", saldo);
                localStorage.setItem("salesforce", salesforce);
                setLoader(false)

            } else {
                //   Alert.alert('Woops!', responseJson.message);
                console.log(responseJson.message);
                setLoader(false)
                //   setLoaderDataUser(false);
                //   setNotifyVersion(true);
            }

        } catch (error) {
            console.log(error, " | ERROR getDataUserApi() | LINE 112")
            setLoader(false)
        }
    }

    const generateTokenAltan = async () => {
        try {
            const response = await fetch('https://apps-ws.spot1.mx/api/api-router', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "api": "getAccessToken"
                })
            })

            const responseJson = await response.json()

            if (response.status == 200) {
                setAccessToken(responseJson.accessToken)

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Woops!!',
                    text: responseJson.description
                })
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: error
            })
            console.log(error)
        }
    }

    const verifyNumber = (data) => {
        console.log("VERIFY NUMBER");
        if (Object.values(data).includes('')) {
            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: 'Completa todos los campos, por favor...',
                showConfirmButton: false,
                timer: 1500
            })

            return false;
        }

        if (data.msisdn.length < 10 || data.msisdnConfirm.length < 10) {
            //   Alert.alert('Woops!', 'Los números deben de contener al menos 10 dígitos, por favor verifica y corrige.');
            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: 'Los números deben de contener al menos 10 dígitos, por favor verifica y corrige.',
                showConfirmButton: false,
                timer: 1500
            })

            return false;
        }

        if (data.msisdn != data.msisdnConfirm) {
            //   Alert.alert('Woops!', 'Los números deben de coincidir.');
            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: 'Los números deben de coincidir.',
                showConfirmButton: false,
                timer: 1500
            })

            return false;
        }

        verifyNumberAPI(data);
    }

    const verifyNumberAPI = async (data) => {
        setLoaderRecharge(true)
        try {
            const response = await fetch('https://apps-ws.spot1.mx/api/api-router', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "api": "consultUF",
                    "data_request": {
                        accessToken,
                        msisdn: data.msisdn
                    }
                })
            })

            const responseJson = await response.json()

            if (response.status == 200) {
                setLoaderRecharge(false)
                setModalIsOpen(true)

                if (responseJson.responseSubscriber.status.subStatus == 'Active') {
                    setPrimaryOffering(responseJson.responseSubscriber.primaryOffering.offeringId)
                    //   setModalRecharge(true)

                    // Swal.fire({
                    //     icon: 'success',
                    //     title: 'Success!!',
                    //     text: "Número válido: " + responseJson.responseSubscriber.primaryOffering.offeringId,
                    //     showConfirmButton: false,
                    //     timer: 1500
                    // })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Woops!!',
                        text: 'Al parecer el número ' + data.msisdn + ' se encuentra en un status en que no se permite realizar recarga.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
                console.log(responseJson.responseSubscriber.status.subStatus, "STATUS | LINE 232 | Home.js");
            } else {
                setLoaderRecharge(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Woops!!',
                    text: responseJson.description,
                    showConfirmButton: false,
                    timer: 1500
                })
            }

        } catch (error) {
            setLoaderRecharge(false)
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: error,
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    const getFunctionChoosed = data => {
        setTypeSale(data.type_sale)
        setPortIn(data.port_in)

        Swal.fire({
            title: 'Ingresa el IMEI del dispositivo en que se insertará la SIM. Esto es para asegurar que estás asignando una línea a un dispositivo compatible:',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off',
            },
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: (dato) => {
                if (!dato) {
                    Swal.showValidationMessage('Debes ingresar un imei');
                } else if (dato.length < 15) {
                    Swal.showValidationMessage('Normalmente los IMEI tienen una longitud de 15 caracteres.');
                }
                return dato;
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {

                //   alert(result.value);
                verifyIMEI({ imei: result.value });
            }
        });

    }

    const verifyIMEI = async (data) => {
        if (Object.values(data).includes('')) {
            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: 'Por favor ingresa un IMEI.',
            })

            return false;
        }
        // setLoaderIMEI(true);
        setImei(data.imei)
        // setDialog(false);
        Swal.fire({
            title: 'Verificando IMEI.',
            html: 'Espera, no te desesperes Pérez...',
            didOpen: () => {
                Swal.showLoading();
            }
        });

        try {
            const response = await fetch('https://apps-ws.spot1.mx/api/api-router', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "api": "imei_status",
                    "data_request": {
                        accessToken,
                        imei: data.imei
                    }
                })
            })

            const responseJson = await response.json()
            // 867377024545521
            // 353052113056042
            // 353779100047499
            if (response.status == 200) {

                if ((responseJson.deviceFeatures.band28 == "NO" && responseJson.deviceFeatures.volteCapable == "NO") || responseJson.imei.blocked != "NO") {
                    console.log("BAD IMEI | LINE 298")
                    // setLoaderIMEI(false);
                    if (responseJson.imei.homologated == "VOZAPP") {
                        console.log("VOZAPP IMEI | LINE 300")
                        setRespImei(responseJson)
                        setModalSaleSim(true)
                        Swal.close();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Woops!! INGRESA UN IMEI VALIDO PARA CONTINUAR.',
                            text: 'No es posible que realices la activación o portabilidad, ya que el resultado de la verificación de IMEI captó algo inusual -> Compatibilidad con BANDA28: ' + responseJson.deviceFeatures.band28 + ' - Compatibilidad con CAPA VoLTE: ' + responseJson.deviceFeatures.volteCapable + ' - Dispositivo Bloqueado: ' + responseJson.imei.blocked + ' - Homologado: ' + responseJson.imei.homologated + '.',
                        })
                    }


                } else if (responseJson.deviceFeatures.band28 == "SI" && responseJson.deviceFeatures.volteCapable == "NO" && responseJson.imei.blocked == "NO") {
                    responseJson.imei.homologated = "VOZAPP";
                    console.log("VOZAPP IMEI | LINE 313")
                    setRespImei(responseJson)
                    setModalSaleSim(true)
                    Swal.close();
                } else if (responseJson.deviceFeatures.band28 == "SI" && responseJson.deviceFeatures.volteCapable == "SI" && responseJson.imei.blocked == "NO") {
                    console.log("GOOD IMEI | LINE 318")
                    setRespImei(responseJson)
                    setModalSaleSim(true)
                    Swal.close();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Woops!! INGRESA UN IMEI VALIDO PARA CONTINUAR.',
                        text: 'No es posible que realices la activación o portabilidad, ya que el resultado de la verificación de IMEI captó algo inusual -> Compatibilidad con BANDA28: ' + responseJson.deviceFeatures.band28 + ' - Compatibilidad con CAPA VoLTE: ' + responseJson.deviceFeatures.volteCapable + ' - Dispositivo Bloqueado: ' + responseJson.imei.blocked + ' - Homologado: ' + responseJson.imei.homologated + '.',
                    })
                    console.log("BAD IMEI | LINE 323")
                }

            } else {
                // setLoaderIMEI(false);

                Swal.fire({
                    icon: 'error',
                    title: 'Woops!!',
                    text: 'Error: ' + responseJson.errorCode + ' - ' + responseJson.description,
                })
                // Alert.alert('Woops!!', 'Error: ' + responseJson.errorCode + ' - ' + responseJson.description);
            }

        } catch (error) {
            console.log(error, 'ERROR')
            // setLoaderIMEI(false);
            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: error,
            })
            // Alert.alert('Woops!!', error);
        }
    }

    const closeModal = () => {
        setModalIsOpen(false);
    }

    const closeModalSaleSim = () => {
        setModalSaleSim(false);
    }

    return (
        <div className="container-pages">
            {loader && (
                <div className="col-12-cust">
                    <Loader title={"Sincronizando tus datos"} />
                </div>

            )}
            <div className="col-12-cust">
                <div className="form">
                    <img src={logo} className="App-logo" alt="logo" />
                    <h2 className="title-form">INGRESAR NUMERO</h2>

                    <div className="input-form-group">
                        <label className="label-form">Número: </label>
                        <input className="input-form" type="number" value={msisdn} onChange={(e) => setMsisdn(e.target.value)} placeholder="5520000000" />
                    </div>

                    <div className="input-form-group">
                        <label className="label-form">Confirmación: </label>
                        <input className="input-form" type="number" value={msisdnConfirm} onChange={(e) => setMsisdnConfirm(e.target.value)} placeholder="5520000000" />
                    </div>

                    {loaderRecharge && (
                        <div className="col-12-cust">
                            <Loader title={"Verificando número..."} />
                        </div>
                    )}

                    <div className="input-form-group">
                        <button type="button" className="btn-primary" onClick={() => verifyNumber({ msisdn, msisdnConfirm })}>RECARGAR</button>
                    </div>
                </div>
                <h2 className="title-form">¿CLIENTE NUEVO?</h2>
                <div className="btn-group">
                    <button type="button" className="btn-primary-outline" onClick={() => getFunctionChoosed({ type_sale: "línea nueva", port_in: 0 })}>
                        ACTIVACION
                    </button>
                    <button type="button" className="btn-primary-outline" onClick={() => getFunctionChoosed({ type_sale: "portabilidad", port_in: 1 })}>
                        PORTABILIDAD
                    </button>
                </div>
            </div>

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Ejemplo de Modal Recarga" style={{
                content: {
                    padding: 0,
                    borderRadius: 10,
                    width: '95%',
                    position: 'absolute',
                    '@media only screen and (max-width: 600px)': {
                        width: '100%',
                        maxWidth: '100%',
                    }
                }
            }}>
                <Recharge setModalIsOpen={setModalIsOpen} msisdn={msisdn} offeringId={primaryOffering} accessToken={accessToken} userID={userID} userName={user_name} style={{width: '100%'}} />
                {/* <button onClick={closeModal}>Cerrar Modal</button> */}
            </Modal>

            <Modal isOpen={modalSaleSim} onRequestClose={closeModalSaleSim} contentLabel="Ejemplo de Modal Linea Nueva" style={{ 
                content: {
                    padding: 0,
                    borderRadius: 10,
                    width: '95%',
                    position: 'absolute',
                    '@media only screen and (max-width: 600px)': {
                        width: '100%',
                        maxWidth: '100%',
                    }
                }
             }}>
                <NewLine setModalSaleSim={setModalSaleSim} accessToken={accessToken} userID={userID} typeSale={typeSale} portIn={portIn} getDataUserAPI={getDataUserAPI} imei={imei} respImei={respImei} />
                {/* <button onClick={closeModal}>Cerrar Modal</button> */}
            </Modal>
        </div>
    )
}