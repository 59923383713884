import React, { useEffect, useState } from 'react'
import { useNavigate, NavLink } from 'react-router-dom'
import { register } from '../helpers/apiRegister'
import Loader from "react-js-loader";

export const ContentRegister = () => {
    const [cellphone, setCellphone] = useState('')
    const [password, setPassword] = useState('')
    const [confirmpassword, setConfirmPassword] = useState('')
    const [notifyLogin, setNotifyLogin] = useState('')
    const [showNotifyDanger, setShowNotifyDanger] = useState(false)
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        localStorage.removeItem("client_id");
        localStorage.removeItem("devices");
    }, [])
    

    const onRegister = async () =>{
        setLoader(true)

        if (password != confirmpassword) {
            setNotifyLogin("Las contraseñas no coinciden.");
            setShowNotifyDanger(true)
            setLoader(false)
        }
    
        if (password.length & confirmpassword.length < 8) {
            setNotifyLogin("La contraseña debe ser mayor a 8 caracteres.");
            setShowNotifyDanger(true)
            setLoader(false)
        }
        let data = {password, cellphone, confirmpassword}
        if (Object.values(data).includes('')) {
            setNotifyLogin("Completa todos los campos del formulario.");
            setShowNotifyDanger(true)
            setLoader(false)

            return false;
        }else{
            setShowNotifyDanger(false)
            setLoader(true)

            const data = await register({cellphone, password, confirmpassword})
            console.log('REGISTER ' + data)
            if (data.http_code == 200) {
                localStorage.setItem("client_id", data.userId);
                localStorage.setItem("devices", JSON.stringify(data.devices));
                navigate('/home-clients',{
                replace: true
                })
            }else{
                setNotifyLogin("Ocurrio un problema, comuniquese con soporte.");
                setShowNotifyDanger(true)
                setLoader(false)

            }
        }
    }
  return (
    <div className='content-options'>
        <div className="m-3 logo" style={{'flex':1}}>
        </div>
        <div className="content-options" style={{'flex':1}}>
            {/* <h2 className='p-white'>Hola, bienvenido</h2> */}
            <h2 className='p-white'>Registrarse</h2>
            <span className='p-white'>Estamos contentos de verte.</span>
        </div>
        <div className="mb-2 inputs-login" style={{'flex':1}}>
            <form >
                <div class="form-group">
                    <label className='p-white' for="exampleInputEmail1">Número</label>
                    <input type="text" style={{'border-radius': '30px'}} name='cellphone' maxLength={10} value={cellphone} onChange={(event)=>setCellphone(event.target.value)} class="form-control form-control-lg" id="exampleInputPassword1" placeholder="2300000000"/>
                </div>
                <div class="form-group">
                    <label className='p-white' for="exampleInputEmail1">Contraseña</label>
                    <input type="password" style={{'border-radius': '30px'}} name='password' value={password} onChange={(event) => setPassword(event.target.value)} class="form-control form-control-lg" id="exampleInputPassword1" placeholder="*********"/>
                </div>
                <div class="form-group">
                    <label className='p-white' for="exampleInputEmail1">Confirmar contraseña</label>
                    <input type="password" style={{'border-radius': '30px'}} name='password' value={confirmpassword} onChange={(event) => setConfirmPassword(event.target.value)} class="form-control form-control-lg" id="exampleInputPassword1" placeholder="*********"/>
                </div>
                {showNotifyDanger && (
                    <label className="label-danger">{notifyLogin}</label>
                )}
                {
                    loader && (
                        <Loader type={'spinner-circle'} size={100}></Loader>
                    )
                }
            </form>
        </div>
        <div className="" style={{'flex':1}}>
            <button className='btn btn-primary btn-lg btn-block' style={{'border-radius': '30px'}} onClick={onRegister}>Registrarse</button>
            <p className='p-white mt-2'>¿Ya tienes una cuenta? <NavLink to="/login-clients">Iniciar sesión </NavLink></p>
        </div>
    </div>
  )
}
