export const STRIPE_PUBLIC_KEY="pk_live_51Mkc0gGMWTI2WWEabzL1WOVi2rcRfBhrXlcZU6kOnJgvyWAjmozbw5kp0jAkmyYIGPXEEcFIaKlZXiWumfSRuyam00M3CXTQ4j"
export const STRIPR_SECRET_KEY="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"

// export const STRIPE_PUBLIC_KEY="pk_test_51Mkc0gGMWTI2WWEaqQSvctap0Y9WIZV8qTBwRcG3DkrzioRooS0kSOgM0IK2DwRCsGcVbgWIUYvfwOJpqQADcwju00kVKfBD6I"
// export const STRIPR_SECRET_KEY="sk_test_51Mkc0gGMWTI2WWEaCdZtgx0iqDEbUSp01eOqt6nol3T1DD2bxMhDqaZIY39J3u6i3ipbu4TMH4oCYZY00raANEPe00Cqk3wH07"

// Llves viejas
// export const STRIPE_PUBLIC_KEY="pk_live_51Mkc0gGMWTI2WWEadusfXBlo0Lp51r63U24p3eBW9S1StsVxWJE7hWU4vHy4oU7PFEBjTeqduTGn6MZ9im9p99aB00I58eQe4G"
// export const STRIPR_SECRET_KEY="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"

