import React, {useEffect, useState} from "react"
import { Link } from 'react-router-dom';
import { ListConsums } from "../../components/Clients/ListConsums"
import { DownloadConsums } from "../../components/Clients/DownloadConsums"
import "../../styles/Consumos.css"
import { FaWifi, FaRegCalendarCheck} from "react-icons/fa6";
import { IoCall, IoChatbubblesOutline  } from "react-icons/io5";
import { TbRecharging } from "react-icons/tb";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export const Consumos =  ({renderNavBarDealers}) => {
    const currentPathLocal = window.location.pathname;
    const [Wifiactive, setWifiactive] = useState(false);    
    const [Callactive, setCallactive] = useState(false);    
    const [SMSactive, setSMSactive] = useState(false);    
    const [Type, setType] = useState('Datos');   
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [calendary, setCaledary] = useState(false);
    const [number, setNumber] = useState("");
    const [numbers, setNumbers] = useState("");
    
    let devices = localStorage.getItem('devices');
    devices = JSON.parse(devices);


    const handleClick = () => {     
        switch (Type) {
            case 'Datos': 
                setWifiactive(!Wifiactive)
                setCallactive(false)
                setSMSactive(false)
                break;
            case 'Voz':
                setCallactive(!Callactive)
                setWifiactive(false)
                setSMSactive(false)
                break;
            case 'SMS': 
                setSMSactive(!SMSactive)
                setCallactive(false)
                setWifiactive(false)
                break;        
            default:
                break;
        }
    };

    const NumberPicker = () => {
        let selectNumbres = new Array();
        
        devices.forEach(device => {
            selectNumbres.push(
                <option value={device.number}>{device.number}</option>
            )
        })
        setNumbers(selectNumbres)
    }


    useEffect(() => {
        renderNavBarDealers(currentPathLocal);
        NumberPicker();
    }, [])
    
    useEffect(() => {
        handleClick();
    }, [Type])

    return (
        // <div id="main">
            <div className="consums-container">
                <span className="consums-title">Consumos</span>
                <div className="consums-content">

                    <select className="selectNumber recharge-select" onChange={ (event) => setNumber(event.target.value)}>
                        <option value="">Seleccione una linea</option>
                        {numbers}
                    </select>

                    <div className="btn-consums_container">
                        <button className={ Wifiactive ? "btn-consums_selector b-active" : "btn-consums_selector"} onClick={()=>setType('Datos')} >
                            <span className={Wifiactive ? "btn-consums_icon active_color" : "btn-consums_icon"} > <FaWifi /> </span>
                            <span className="btn-consums_text">Internet</span>
                        </button>
                        <button className={ Callactive ? "btn-consums_selector b-active" : "btn-consums_selector"} onClick={()=>setType('Voz')} > 
                            <span className={Callactive ? "btn-consums_icon active_color" : "btn-consums_icon"} ><IoCall /></span>
                            <span className="btn-consums_text"> Llamadas </span>
                        </button>
                        <button className={ SMSactive ? "btn-consums_selector b-active" : "btn-consums_selector"} onClick={()=>setType('SMS')} > 
                            <span className={SMSactive ? "btn-consums_icon active_color" : "btn-consums_icon"} ><IoChatbubblesOutline /></span>
                            <span className="btn-consums_text">Mensajes</span>
                        </button>

                        <span className="line"></span>
                    </div>
                    <DownloadConsums
                        type={Type} 
                        phone={number}
                        dateStart={startDate}
                        dateEnd={endDate}/>
                    <div className="consums-calendar_container">
                        <button className="consums-calendar_btn" onClick={()=>setCaledary(!calendary)}>Asignar fechas</button>
                        <div className={calendary ? "consums-calendar calendar-active": "consums-calendar"}>

                            <div className="calendary-container">
                                <span>Fecha inicial</span>
                                <DatePicker
                                    showIcon
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className="calendary"
                                    icon={<FaRegCalendarCheck />}
                                    />
                            </div>

                            <div className="calendary-container">
                                <span>Fecha final</span>
                                <DatePicker
                                    showIcon
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    className="calendary"
                                    icon={<FaRegCalendarCheck />}
                                    />
                            </div>
                        </div>    
                    </div>
                    <ListConsums type={Type} 
                        phone={number}
                        dateStart={startDate}
                        dateEnd={endDate}/>

                    <div className="consums-btn_navigation">
                        <button className="btn-navigation btn_danger-navigation">
                            <MdOutlineKeyboardDoubleArrowLeft /> 
                            <span>Regresar</span>
                        </button>
        
                        <Link className="btn-navigation btn_info-navigation" to={`/recarga?number=${number}`}>
                        {/* <Link to="/recarga"  className="btn-navigation btn_info-navigation"  params={{ number: number }}> */}
                            <TbRecharging />
                            <span>Recargar</span>
                        </Link>
                    </div>
                </div>
            </div>
        // </div>
    )
}

