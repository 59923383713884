export async function uf(dn){
    try {
        const response = await fetch(`https://apps-ws.spot1.mx/consultUf?msisdn=${dn}&product=MOV`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
        const data = await response.json()
        return data
    } catch (error) {
        console.log(error)
        return 
    }
}