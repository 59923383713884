import React, {useEffect, useState} from "react"
import "../../styles/Recargas.css"
import { Elements } from "@stripe/react-stripe-js";
import { CardForm } from "../../components/Stripe.jsx"
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from '../../helpers/env.js'
import { getAllRates, verifyRates } from "../../api/rates.js";
import { useLocation } from "react-router-dom"

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export const Recarga =  ({renderNavBarDealers}) => {
    const [rates, setRates] = useState([{}]);
    const [Planes, setPlanes] = useState([]);
    const [rateSelect, setRateSelect] = useState([{}]);
    const [OffertSelect, setOffertSelect] = useState([{}]);

    const location = useLocation()
    const number = new URLSearchParams(location.search)

    const currentPathLocal = window.location.pathname;

    useEffect(() => {
        renderNavBarDealers(currentPathLocal);
        searchOffer()
    }, [])
    
    const searchOffer = async () => {
        let offerts = [];
        let ConsultRates = await getAllRates();
        ConsultRates = ConsultRates['offers']

        const rates_limit = await verifyRates("2382902244");
        await setRates(ConsultRates);
        
        offerts.push(<option value="0">Planes Disponibles</option>)
        if (ConsultRates != undefined) {
            for (let i = 0; i < rates_limit.length; i++) {
                for (let index = 0; index < ConsultRates.length; index++) {
                    if (rates_limit[i]['rate_id'] == ConsultRates[index].rate_id ) {
                        // console.log(ConsultRates[index]);
                        ConsultRates = ConsultRates.filter(elem => elem != ConsultRates[index]);
                    }
                }
            }  

            for (let index = 0; index < ConsultRates.length; index++) {
                offerts.push(
                    <option value={ConsultRates[index].rate_id}>{ConsultRates[index].name} ${ConsultRates[index].price_sale }.00</option>
                )
            }
        }

        setPlanes(offerts)
    }

    // const handleSelect = async (rate) =>{
    //     await setRateSelect(rate);
    // }

    return (
        <div className="main">
            <div className="recharge-container">
                <span className="recharge-title">Recargas</span>
                <div className="recharge-content">
                    <select className="recharge-select" onChange={ (event) => setRateSelect(event.target.value)}>
                        {Planes}
                    </select>

                    <Elements stripe={stripePromise}>
                        <CardForm offers={rates} rate={rateSelect} number={number.get("number")} styleForm="recharge_form" styleBtn="recharge_btn"/>
                    </Elements>
                </div>
            </div>
        </div>
    );

}


