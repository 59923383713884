import React, { useState, useEffect } from "react"
import '../../styles/Profile.css'
import imgPerson from '../../img/person.webp';
import robot from '../../img/Robot.png';
import Swal from 'sweetalert2';

import { ImExit } from "react-icons/im";
import { FaSave } from "react-icons/fa";

export const Profile = ({ renderNavBarDealers }) => {
  const currentPathLocal = window.location.pathname;

  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [password, setPassword] = useState('');
  const [clientID, setClientID] = useState('');
  const [userData, setUserData] = useState({
    name: '',
    lastname: '',
    cellphone: '',
    email: '',
    // password: '',
    passwordConfirm: ''
  });
  const [userInput, setUserInput] = useState({});

  useEffect(() => {
    renderNavBarDealers(currentPathLocal);
    const client = localStorage.getItem("client_id");
    if (client) {
      const result = JSON.parse(client);
      setClientID(result)
    }
    getClient();
  }, [clientID]);


  function getClient() {
    const id_client = clientID;
    fetch(`https://apps-ws-test.spot1.mx/get-user?id_client=${id_client}`)
      .then(response => response.json())
      .then(data => {
        console.log('Datos recibidos:', data);
        if (data && data.length > 0 && data[0] !== '') {
          setUserData(data[0]);
          setUserInput(data[0]);
        }
      })
      .catch(error => {
        console.error('Error al obtener datos:', error);
      });
  }

  function handleChange(e) {
    // const { name, value } = e.target;
    setUserInput(prevState => ({
      ...prevState,
      // [name]: name === 'password' ? value : value.trim()
      [e.target.name]: e.target.value
    }));
  }

  const updateClient = (e) => {
    e.preventDefault();
    const nameInput = document.getElementById('name').value;
    const lastnameInput = document.getElementById('lastname').value;
    const emailInput = document.getElementById('email').value;
    const cellphoneInput = document.getElementById('cellphone').value;
    const passwordInput = document.getElementById('password').value;
    const passwordConfirmInput = document.getElementById('passwordConfirm').value;

    const totalPasswordInput = passwordInput.length;

    if (totalPasswordInput == 0 || totalPasswordInput > 8) {
      const clientData = {
        name: nameInput,
        lastname: lastnameInput,
        email: emailInput,
        cellphone: cellphoneInput,
        password: passwordInput,
        passwordConfirm: passwordConfirmInput
      };
      // console.log(clientData);

      fetch(`https://apps-ws-test.spot1.mx/updateClient`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(clientData)
      })
        .then(response => response.json())
        .then(data => {
          // console.log("data", data);

          const http_code = data.http_code;
          const message = data.message;

          if (http_code == 200) {
            Swal.fire({
              icon: 'success',
              title: 'Exito!!',
              text: message,
              showConfirmButton: false,
              timer: 1500
            })
            return false;
          } else if (http_code == 500) {
            Swal.fire({
              icon: 'error',
              title: 'Woops!!',
              text: message,
              showConfirmButton: false,
              timer: 1700
            })
            return false;
          }

        })
        .catch(error => {
          console.error('Error:', error);
        });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Woops!!',
        text: "La contraseña debe tener mínimo 8 carácter",
        showConfirmButton: true,
        // timer: 1700
      })
      return false;
    }
  };


  // console.log("dataClient", userInput);
  const borrarLocalStorage = () => {
    localStorage.removeItem(userInput);

    const datosBorrados = localStorage.getItem(userInput) === null;
    console.log("datosBorrados",datosBorrados);

    if(datosBorrados){
      window.location.href = '/login-clients';
    } else{
      console.log("No se borraron");
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    updateClient(e);
  }

  return (
    <div className="col-12-cust">
      <div className="navbar-container-name">
        <nav className='navbar-items-client'>
          <div>
            <div className="mt-3 ml-2">
              <h1 className="name-client">Bienvenido {userData.name} {userData.lastname}</h1>
            </div>
          </div>
        </nav>
      </div>
      <div className="container-start form-client">
        <form className="auth-form-container-client" onSubmit={handleSubmit}>
          <div className="form-cotainer-client-input">

            <div className="input-name">
              <label className="label-form-profile" htmlFor="username">Nombre: </label>
              <input className="input-form-profile" type="text" onChange={handleChange} placeholder="Nombre" id="name" name="name" value={userInput.name || userData.name} />
            </div>

            <div className="input-apellido">
              <label className="label-form-profile" htmlFor="username">Apellido: </label>
              <input className="input-form-profile" type="text" onChange={handleChange} placeholder="Apellido" id="lastname" name="lastname" value={userInput.lastname || userData.lastname} />
            </div>

            <div className="input-pws1">
              <label className="label-form-profile" htmlFor="username">Contraseña: </label>
              <input className="input-form-profile" type="password" onChange={(e) => setPassword(e.target.value)} placeholder="*****" id="password" name="password" value={password} />
              {/* <input className="input-form-profile" type="password" onChange={handleChange} placeholder="*****" id="password" name="password" value={userInput.password !== undefined && userInput.password !== '' ? userInput.password : ''} /> */}
            </div>

            <div className="input-pws1">
              <label className="label-form-profile" htmlFor="username">Confirmar Contraseña: </label>
              <input className="input-form-profile" type="password" onChange={(e) => setPasswordConfirm(e.target.value)} placeholder="****" id="passwordConfirm" name="passwordConfirm" value={passwordConfirm} />
            </div>

            {/* <div className="input-password">
              <label className="label-form-profile" htmlFor="username">Confirmar Contraseña: </label>
              <input className="input-form-profile" type="password" onChange={(e) => setPasswordConfirm(e.target.value)} placeholder="****" id="passwordConfirm" name="passwordConfirm" value={passwordConfirm} />
            </div> */}

            <div className="input-email">
              <label className="label-form-profile" htmlFor="username">E-mail: </label>
              <input className="input-form-profile" type="email" onChange={handleChange} placeholder="E-mail" id="email" name="email" value={userInput.email || userData.email} />
            </div>
            {/* 
            <div className="input-apellido">
              <label className="label-form-profile" htmlFor="username">Teléfono: </label> */}
            <input className="input-form-profile" type="hidden" onChange={handleChange} placeholder="Télefono" id="cellphone" name="cellphone" value={userInput.cellphone || userData.cellphone} />
            {/* </div>  */}

            <button className="btn-profile"><FaSave /> Guardar</button>
            <button onClick={borrarLocalStorage} className="btn-profile_close"><ImExit /> Cerrar sesión</button>
          </div>
        </form>
        <img src={robot} className="img-robot" alt="logo" />
      </div>
    </div>
  );
};