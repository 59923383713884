import { BrowserRouter, Routes, Route, Link } from "react-router-dom"
import logo from './img/Logo-spotuno.png';
import './App.css';
import { AppRouter } from "./components/AppRouter"
function App() {

  return (
    <AppRouter/>
  );
  // return (
  //   <div className="App">
  //     <img src={logo} className="App-logo" alt="logo" />
  //     <BrowserRouter>
  //       <Routes>
  //         <Route path="/" element={<Login />} />
  //         <Route path="/home" element={<Home />} />
  //       </Routes>
  //     </BrowserRouter> */

  //   </div>
  // );

}

export default App;
