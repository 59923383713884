import { CardElement, useStripe, useElements} from "@stripe/react-stripe-js";
import { rechargeStripe } from "../api/recharge";
import Swal from 'sweetalert2';

export const CardForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    let mensaje

    const filterOffert = async (rates) =>{
        if (rates != undefined) {
            for (let index = 0; index < rates.length; index++) {
                if (rates[index].rate_id == props.rate) {
                    // console.log( rates[index].offerID);
                    return rates[index].offerID;
                }
            }
        }
    }
    
    const handleSubmit = async () => {
        const rate = props.rate;
        const number = props.number;

        const cardElement = elements.getElement("card");

        let request = await stripe.createToken(cardElement).then(function(result){
            //  result.error - result.token
            if (result.error) {
                const error = result.error
                return {result:error.message, http_code:500}
            }else{
                const token = result.token
                return {result:token, http_code:200}
            }
        });

        if (request["http_code"] == 500) 
            return Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: request["result"],
                showConfirmButton: true,
            })

        if (typeof rate == "object") 
            return Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: "Selecciones una oferta",
                showConfirmButton: true,
            })

        Swal.fire({
            title: '¿Quiere proceder con su compra?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#47a447',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro.'
        }).then( async (result) => {
            if (result.isConfirmed) {
                await recharge(number, request["result"].id, rate, 38);
            }
        })
    };

    const recharge = async (number, token, rate, client_id ) =>{
        const offerID = await filterOffert(props.offers)
        // console.log(number, token, offerID, rate, client_id);
        Swal.fire({
            html: ' Procesando...',
            didOpen: () => {
                Swal.showLoading();
            }
        });

        const response = await rechargeStripe(number, token, [offerID, rate], client_id);

        await Swal.close();
        if (response.ticket == undefined) {
            if (response.message == undefined) {
                mensaje = "Intente mas tarde."
            }else{
                mensaje = response.message
            }
            
            await Swal.fire({
                icon: 'info',
                title: 'Info.',
                text: mensaje
            })
        }else{
            await Swal.fire({
                icon: 'info',
                title: 'Info.',
                footer: `<a href="${response.ticket}" target="_blank" >Abrir ticket de compra.</a>`,
                text: response.message
            })
        }

        
    }

    return(
        <div className={props.styleForm} >
            <CardElement  />
            <button className={props.styleBtn} disabled={!stripe} onClick={()=>handleSubmit()}>Pagar</button>
        </div>
    )
};