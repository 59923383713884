import React, {useEffect, useState} from "react"
import { Link } from 'react-router-dom';
import "../../styles/Numbers.css"
import "../../styles/Consumos.css"
import { FaWifi, FaRegCalendarCheck} from "react-icons/fa6";
import { IoCall, IoChatbubblesOutline  } from "react-icons/io5";
import { TbRecharging } from "react-icons/tb";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export const Numbers =  ({renderNavBarDealers}) => {
    const currentPathLocal = window.location.pathname;
    const [number, setNumber] = useState("");
    const [numbers, setNumbers] = useState("");

    let devices = localStorage.getItem('devices');
    devices = JSON.parse(devices);

    const NumberPicker = () => {
        let selectNumbres = new Array();
        
        devices.forEach(device => {
            selectNumbres.push(
                <Link className="link-number number-container" to={`/recarga?number=${device.number}`}>
                    {device.number}
                </Link>
            )
        })
        setNumbers(selectNumbres)
    }

 

    useEffect(() => {
        renderNavBarDealers(currentPathLocal);
        NumberPicker()
    }, [])
    
    return (
        // <div id="main">
            <div className="numbers-container">
                <span class="consums-title">Numeros</span>
                <p className="p-alert">Click sobre el numero que desea recargar</p>
                {numbers}
            </div>
        // </div>
    )
}

