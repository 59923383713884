import React, { useEffect } from 'react'
import { ContentRegister, ImageBackground } from '../components';

export const Registe = ({renderNavBarDealers}) => {
    const currentPathLocal = window.location.pathname;

  useEffect(() => {
    renderNavBarDealers(currentPathLocal);
  }, [])
    return(
        <>
      <div className="contenedor">
        <div className='contenedor-pre-login'>
          <ContentRegister/>
        </div>
        <div className='bg-pre-login'>
          <ImageBackground/>
        </div>
      </div>
    </>
    )
}
