import React, { useState, useEffect } from 'react'
import '../../../styles/Generic.css'
import '../../../styles/Modal.css'
import logo from '../../../img/logo-white.png'
import search from '../../../img/search-white.png'
import check from '../../../img/check.png'
import Swal from 'sweetalert2';
import { formatEDDay, formatEDMonth, formatEDYear, formatEDHour, formatEDMinute } from '../../../helpers'

const productsDictionary = {
    MOV: require('../../../img/mov-short.png'),
}

const NewLine = ({ setModalSaleSim, accessToken, userID, typeSale, portIn, getDataUserAPI, imei, respImei }) => {

    const [saldo, setSaldo] = useState(0)
    const [salesforce, setSalesforce] = useState(0)

    const [icc, setIcc] = useState('')
    const [clientID, setClientID] = useState(0)
    const [clientName, setClientName] = useState('')
    const [clientLastname, setClientLastname] = useState('')
    const [address, setAddress] = useState('')

    const [dateActivation, setDateActivation] = useState(new Date().toISOString().split('T')[0])
    const [datePortIn, setDatePortIn] = useState(new Date())

    const [nip, setNip] = useState('')
    const [msisdn, setMsisdn] = useState('')
    const [numberID, setNumberID] = useState(0)
    const [msisdnPorted, setMsisdnPorted] = useState('')
    const [imsiT, setImsi] = useState('')
    const [product, setProduct] = useState('')
    const [dida, setDida] = useState('')
    const [dcr, setDcr] = useState('')

    const [offeringId, setOfferingId] = useState('')
    const [rates, setRates] = useState([])

    const [effectiveDateShow, setEffectiveDateShow] = useState('')
    const [orderIDShow, setOrderIDShow] = useState(0)
    const [amountShow, setAmountShow] = useState(0)
    const [doneActivation, setDoneActivation] = useState(false)

    // FLAGS DE OPERACION
    const [verificar, setVerificar] = useState(true)
    const [activar, setActivar] = useState(false)

    const addOneDay = () => {

        let currentDateObj = new Date();
        let numberOfMlSeconds = currentDateObj.getTime();
        let addMlSeconds = (60 * 60000) * 6;
        let date = new Date(numberOfMlSeconds - addMlSeconds);
        setDateActivation(date.toISOString().split('T')[0]);

        // console.log(date, " | DATE NOW |")
        date.setDate(date.getDate() + 1);
        const nextDate = date.toISOString().split('T')[0];
        setDatePortIn(nextDate);
        console.log(nextDate, " | NEXT DAY 1")

    };


    useEffect(() => {
        const saldoStrg = localStorage.getItem('saldo');
        const salesforceStrg = localStorage.getItem('salesforce');
        setSaldo(saldoStrg)
        setSalesforce(salesforceStrg)
        addOneDay();
    }, [])

    function formatearFecha(fecha) {
        const date = new Date(fecha);
        const año = date.getFullYear();
        const mes = ('0' + (date.getMonth() + 1)).slice(-2);
        const dia = ('0' + date.getDate()).slice(-2);
        return `${año}-${mes}-${dia}`;
    }

    function compararFechas(dataPortDate, dataActDate) {
        const fechaFormateada1 = formatearFecha(dataPortDate);
        const fechaFormateada2 = formatearFecha(dataActDate);

        if (fechaFormateada1 <= fechaFormateada2) {
            return -1;
        } else if (fechaFormateada1 > fechaFormateada2) {
            return 1;
        }
    }

    const closeModal = () => {

        setModalSaleSim(false);
        getDataUserAPI();
    }

    const cancelOperation = () => {
        setActivar(false);
        setVerificar(true);
    }

    const validateForm = (data) => {
        // console.log(data," | DATA VALIDATE FORM |")

        if (userID == 0 || userID == "empty" || userID == null) {
            Swal.close();
            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: 'Al parecer no he podido identificar qué usuario eres. Es necesario que inicies sesión de nuevo, de lo contrario no podrás continuar.',
                showConfirmButton: false,
                timer: 1500
            })
            // setLoader(false)
            return false;
        }

        if (portIn == 1 && data.function == 0) {
            if (data.nip.length != 4) {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Woops!!',
                    text: 'El NIP para portar debe ser de 4 dígitos exactos.',
                    showConfirmButton: false,
                    timer: 1500
                })
                // setLoader(false)
                return false;
            }

            if (data.msisdnPorted.length != 10) {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Woops!!',
                    text: 'El número a portar debe ser de 10 dígitos exactos.',
                    showConfirmButton: false,
                    timer: 1500
                })
                // setLoader(false)
                return false;
            }
        }

        console.log(data, 'VALIDATE FORM')
        if (Object.values(data).includes('')) {
            Swal.close();
            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: 'Debes completar todos los campos.',
                showConfirmButton: false,
                timer: 1500
            })
            return false;
        }



        let currentDateObj = new Date(data.dateActivation);
        let numberOfMlSeconds = currentDateObj.getTime();
        let addMlSeconds = (60 * 60000) * 6;
        let newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
        data.dateActivation = newDateObj;

        let currentDateObjPort = new Date(data.datePortIn);
        let numberOfMlSecondsPort = currentDateObjPort.getTime();
        let addMlSecondsPort = (60 * 60000) * 6;
        let newDateObjPort = new Date(numberOfMlSecondsPort - addMlSecondsPort);
        data.datePortIn = newDateObjPort;

        if (portIn == 1 && data.function == 0) {
            let dataPortDate = data.datePortIn;
            dataPortDate = dataPortDate.toISOString();
            dataPortDate = dataPortDate.substring(0, 10);

            let dataActDate = data.dateActivation;
            dataActDate = dataActDate.toISOString();
            dataActDate = dataActDate.substring(0, 10);

            const resultado = compararFechas(dataPortDate, dataActDate);
            console.log("#################################################################");
            if (resultado === -1) {
                console.log("ELIJA OTRA FECHA DE PORTABILIDAD | LINE 132");
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Woops!!',
                    text: 'La fecha de portabilidad debe ser mayor a la fecha de activación.',
                    showConfirmButton: false,
                    timer: 2000
                })
                // setLoader(false)
                return false;
            } else if (resultado === 1) {
                console.log("FECHA DE PORTABILIDAD VALIDA... | LINE 143");
            }
            console.log("#################################################################");
        }

        if (data.function == 0) {
            Swal.fire({
                title: 'Verificando datos.',
                html: 'Espera, no te desesperes Pérez...',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            getNumberByIcc();
        } else if (data.function == 1) {
            Swal.fire({
                title: 'ATENCION!!',
                text: 'Está seguro de realizar la activación del ICC ' + icc + 'F?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, continuar',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: 'Realizando ' + typeSale + '.',
                        html: 'Espera, no te desesperes Pérez...',
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });

                    activateMSISDN(data);
                }
            });
        }
    }

    const getNumberByIcc = async () => {

        try {
            const response = await fetch('https://apps-ws.spot1.mx/get-number-by-icc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    icc: icc + 'F'
                })
            })

            const responseJson = await response.json()
            console.log(responseJson, 'GET NUMBER BY ICC')

            if (response.status == 200) {
                const { number } = responseJson
                const { MSISDN, producto, id, imsi } = number
                setNumberID(id)
                setMsisdn(MSISDN)
                setProduct(producto)
                setImsi(imsi)
                consultUF({ msisdn: responseJson.number.MSISDN });

                // console.log(responseJson.number,'RESPONSE FROM API');
            } else {
                // setLoader(false);
                // Alert.alert('Error: ', responseJson.message);
                Swal.fire({
                    icon: 'error',
                    title: 'Woops!!',
                    text: responseJson.message,
                    showConfirmButton: false,
                    timer: 2000
                })
            }

        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: error,
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    const consultUF = async data => {
        // setMessageLoader('Validando status de la SIM...')

        try {
            const response = await fetch('https://apps-ws.spot1.mx/api/api-router', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "api": "consultUF",
                    "data_request": {
                        accessToken,
                        msisdn: data.msisdn
                    }
                })
            })

            const responseJson = await response.json()
            console.log(responseJson, 'RESPONSE FROM API ALTAN REDES');

            if (response.status == 200) {
                // setLoader(false);


                if (responseJson.responseSubscriber.status.subStatus == 'Idle') {
                    getRates({ msisdn: data.msisdn });
                    // Swal.fire({
                    //     icon: 'success',
                    //     title: 'Éxito!!',
                    //     text: "Número válido para activar.",
                    //     showConfirmButton: false,
                    //     timer: 2000
                    // })
                    console.log("NUMERO APTO PARA REALIZAR EL MOVIMIENTO...");
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Woops!!',
                        text: 'Al parecer el número la SIM con ICC ' + icc + ' se encuentra en un status en que no se permite realizar una activación de línea nueva.',
                        showConfirmButton: false,
                        timer: 2000
                    })
                    // setLoader(false)
                }
                // console.log(responseJson.status);
            } else {
                // setLoader(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Woops!!',
                    text: responseJson.description,
                    showConfirmButton: false,
                    timer: 2000
                })
                // setLoader(false)
            }

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: error,
                showConfirmButton: false,
                timer: 2000
            })
            console.log(error)
        }
    }

    const getRates = async data => {
        // setMessageLoader('Verificando planes disponibles...')
        Swal.fire({
            title: 'Verificando planes disponibles.',
            html: 'Espera, no te desesperes Pérez...',
            didOpen: () => {
                Swal.showLoading();
            }
        });
        try {
            const response = await fetch('https://apps-ws.spot1.mx/get-rates-activation-pos', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    msisdn: data.msisdn
                })
            })

            const responseJson = await response.json();

            if (response.status == 200) {
                setOfferingId('')
                setRates(responseJson.rates)
                // setLoader(false)
                setVerificar(false)
                setActivar(true)
                Swal.close();
                console.log(responseJson, 'GET RATES RESPONSE')
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Woops!!',
                    text: responseJson.message,
                    showConfirmButton: false
                })
            }

            //   console.log(responseJson, 'RATES')
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: error,
                showConfirmButton: false
            })
        }
    }

    const getInfoCurrentOMV = async (data) => {
        console.log("VERIFICANDO INFORMACIÓN DE OPERADORA ACTUAL...")

        if (data.nip.length != 4 && portIn == 1) {
            return false;
        }

        if (data.msisdnPorted.length != 10 && portIn == 1) {
            return false;
        }

        try {
            const response = await fetch('https://apps-ws.spot1.mx/api/api-router', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "api": "getInfoCurrentOMV",
                    "data_request": {
                        accessToken,
                        msisdn: msisdnPorted
                    }
                })
            })

            const responseJson = await response.json()

            if (response.status == 200) {
                const { msisdn, cr, ida, type } = responseJson
                setDida(ida)
                setDcr(cr)

                console.log(responseJson, ' *************** RESPONSE FROM API CURRENT OMV ***************');
            } else {
                // setLoader(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Woops!!',
                    text: responseJson.description,
                    showConfirmButton: false,
                    timer: 2000
                })
            }

        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: error,
                showConfirmButton: false
            })
        }
    }

    const activateMSISDN = async (data) => {
        // setActivar(false);
        // setLoader(true)
        // setMessageLoader('Realizando activación...')
        let offerID = data.offerID;
        let offerIdentificator = data.id;
        let offerPrice = data.price;
        let alta_offer_id = data.alta_offer_id;
        offerPrice = parseFloat(offerPrice);
        let saldoUser = parseFloat(saldo)

        let scheduleDate = data.dateActivation;
        scheduleDate = scheduleDate.toISOString();
        scheduleDate = scheduleDate.substring(0, 10);
        scheduleDate = scheduleDate.replace(/-/gm, "");
        console.log(scheduleDate, "SCHEDULE DATE ACTIVATE MSISDN")
        let dataPortDate = data.datePortIn;
        dataPortDate = dataPortDate.toISOString();
        dataPortDate = dataPortDate.substring(0, 10);

        // console.log(offerID, 'OFFER ID');
        // console.log(offerIdentificator, 'ID');
        // console.log(offerPrice, 'PRICE');
        console.log(dataPortDate, " [PORT DATE ACTIVATE MSISDN] ")

        if ((offerPrice <= saldoUser && salesforce == 0) || salesforce == 1) {
            // console.log('ACTIVATION TRUE')
            try {

                const response = await fetch('https://apps-ws.spot1.mx/api/api-router', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "api": "activate_msisdn",
                        "data_request": {
                            accessToken,
                            msisdn: msisdn,
                            offeringId: offerID.toString()
                        }
                    })
                })

                const responseJson = await response.json()
                console.log(responseJson, 'RESPONSE FROM API ALTAN REDES');

                if (response.status == 200) {
                    // setLoader(false);
                    console.log("---------- ACTIVATION SUCCESSFULLY ----------");
                    Swal.close();
                    setEffectiveDateShow(responseJson.effectiveDate);
                    setOrderIDShow(responseJson.order.id);
                    setAmountShow(offerPrice)
                    setOfferingId(offerID)
                    setActivar(false)
                    setDoneActivation(true)
                    // Alert.alert('Success', 'Activación hecha con éxito, el número asignado es [' + responseJson.msisdn + '].');
                    // setLoader(false);
                    saveActivation({ offerID, offerIdentificator, effectiveDate: responseJson.effectiveDate, orderID: responseJson.order.id, offerPrice, alta_offer_id, scheduleDate, dataPortDate })

                    // console.log(responseJson.status);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Woops!!',
                        text: responseJson.description
                    })
                }

            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Woops!!',
                    text: error
                })
                console.log(error)
            }
        } else {
            // setLoader(false)
            // setVerificar(true)
            // setActivar(false)

            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: 'No cuentas con saldo suficiente para realizar este movimiento.',
                showConfirmButton: true,
                timer: 2000
            })
            console.log('ACTIVATION FALSE')
        }
    }

    const saveActivation = async data => {
        let saldoNew = 0;
        if (salesforce == 0) {
            saldoNew = parseFloat(saldo) - parseFloat(data.offerPrice);
            console.log(saldoNew, "RESTA DE SALDO")
        }

        let now = new Date();
        let numberOfMlSeconds = now.getTime();
        let addMlSeconds = (60 * 60000) * 6;
        let newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
        now = newDateObj;

        console.log(data.dataPortDate, " [SAVE ACTIVATION PORT DATE] ")

        let imeiObj = {
            band28: respImei.deviceFeatures.band28,
            brand: respImei.deviceFeatures.brand,
            volteCapable: respImei.deviceFeatures.volteCapable,
            blocked: respImei.imei.blocked,
            homologated: respImei.imei.homologated,
            imei: respImei.imei.imei,
            soportaESIM: respImei.imei.soportaESIM,
            sub_category: respImei.imei.sub_category
        };

        let request = {
            msisdn,
            number_id: numberID,
            offerID: data.offerID,
            pos_rate_id: data.offerIdentificator,
            offer_id: data.alta_offer_id,
            client_name: clientName,
            client_lastname: clientLastname,
            clientID,
            address,
            date_activation: data.effectiveDate,
            order_id: data.orderID,
            amount: data.offerPrice,
            dealer_id: userID,
            saldo_user: saldoNew,
            portIn,
            nip,
            datePortIn: data.dataPortDate,
            msisdnPorted,
            salesforceUser: salesforce,
            dida,
            dcr,
            icc,
            imsi: imsiT,
            id: now,
            imeiObj: imeiObj
        }

        // acumularObjeto(request);
        setSaldo(saldoNew);

        localStorage.setItem('saldo', saldoNew);

        // try {
        //     await AsyncStorage.setItem('saldoStorage', JSON.stringify(saldoNew));
        // } catch (error) {
        //     Alert.alert('Woops!', 'Al parecer tu saldo nuevo no se actualizó, comunica esto a Soporte Técnico para que no tengas problemas en tus próximos movimientos.');
        // }
        // console.log(request,'DATA TO REQUEST API ALTCEL 2');
        console.log(request, 'DATA TO SAVE ACTIVATION')

        try {
            const response = await fetch('https://apps-ws.spot1.mx/save-data-activation-pos', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            })

            const responseJson = await response.json()
            console.log('******************** RESPONSE FROM ROUTE SAVE ACTIVATION ********************')
            console.log(responseJson)
            console.log('******************** RESPONSE FROM ROUTE SAVE ACTIVATION ********************')

            if (response.status == 200) {

                // Swal.fire({
                //     icon: 'success',
                //     title: 'Good job!!!',
                //     text: responseJson
                // })

                // console.log(responseJson.number,'RESPONSE FROM API');
            } else {
                // setLoader(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Woops!!',
                    text: responseJson.message
                })
            }

        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: error
            })
        }
    }
    return (
        <div className='col-12-cust'>
            <div className='col-12-cust banner-container'>
                <div className='banner-modal'>
                    <div>
                        <img src={logo} className="logo" alt="logo" />
                    </div>
                    <div>
                        <h1 className='title-banner-modal'>{typeSale}</h1>
                    </div>
                    <div>
                        <button className='btn-close' type='button' onClick={closeModal}>X</button>
                    </div>

                </div>
            </div>
            <div className='col-12-cust'>
                <div className='banner-card'>
                    <h3 className='banner-card-title'>Saldo Disponible</h3>
                    <h1 className='banner-card-title-h1'>${parseFloat(saldo).toFixed(2)}</h1>
                    <h3 className='banner-card-title'>IMEI COMPTAIBLE: {imei}</h3>
                    {respImei.imei.homologated == "VOZAPP" && (
                        <h3 className='banner-card-title'>Informa al cliente que para poder utilizar su linea tendrá que descargar VOZAPP.</h3>
                    )}
                </div>
            </div>

            {verificar && (
                <div className="form-row">
                    {/* <h2 className="title-form">INGRESAR NUMERO</h2> */}

                    <div className='col-12 border-bottom my'>
                        <div className="form-group col-4">
                            <label className="label-group">ICC: </label>
                            <input className="input-group" type="number" value={icc} onChange={(e) => setIcc(e.target.value)} placeholder="8952140060000000000" />
                        </div>
                    </div>
                    <div className='col-12 my'>
                        <h3 className='subtitle-form'>DATOS DEL CLIENTE</h3>
                    </div>


                    <div className="form-group col-4">
                        <label className="label-group">Nombre: </label>
                        <input className="input-group" type="text" value={clientName} onChange={(e) => setClientName(e.target.value)} placeholder="Nombre" />
                    </div>
                    <div className="form-group col-4">
                        <label className="label-group">Apellidos: </label>
                        <input className="input-group" type="text" value={clientLastname} onChange={(e) => setClientLastname(e.target.value)} placeholder="Apellidos" />
                    </div>
                    <div className="form-group col-4">
                        <label className="label-group">Direccion: </label>
                        <input className="input-group" type="text" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Direccion completa" />
                    </div>
                    <div className='col-12 my d-flex justify-content-center'>
                        <button type="button" className="btn-primary" > <img src={search} className="img-icon" alt="logo" /> BUSCAR CLIENTE</button>
                    </div>
                    <div className='col-12 border-bottom my'></div>

                    <div className="form-group col-4">
                        <label className="label-group">Fecha Activación: </label>
                        <input className="input-group" type="date" value={dateActivation} onChange={(e) => setDateActivation(e.target.value)} />
                    </div>

                    {portIn == 1 && (
                        <div className="form-group col-4">
                            <label className="label-group">Fecha Portabilidad: </label>
                            <input className="input-group" type="date" value={datePortIn} onChange={(e) => setDatePortIn(e.target.value)} />
                        </div>
                    )}

                    <div className='col-12 border-bottom my'></div>
                    {portIn == 1 && (
                        <div className='col-12 my'>
                            <h3 className='subtitle-form'>NIP y No. Portar</h3>
                        </div>
                    )}

                    {portIn == 1 && (
                        <div className="form-group col-4">
                            <label className="label-group">NIP: </label>
                            <input className="input-group" type="number" maxLength={4} value={nip} onChange={(e) => setNip(e.target.value)} placeholder="1234" />
                        </div>
                    )}

                    {portIn == 1 && (
                        <div className="form-group col-4">
                            <label className="label-group">No. A Portar: </label>
                            <input className="input-group" type="text" value={msisdnPorted} onChange={(e) => setMsisdnPorted(e.target.value)} placeholder="5551234567" />
                        </div>
                    )}

                    {portIn == 1 ?
                        <div className='col-12 my d-flex justify-content-center'>
                            <button type="button" className="btn-primary" onClick={() => { validateForm({ icc, clientName, clientLastname, address, dateActivation, datePortIn, nip, msisdnPorted, function: 0 }); getInfoCurrentOMV({ nip, msisdnPorted }); }} >VERIFICAR</button>
                        </div> :
                        <div className='col-12 my d-flex justify-content-center'>
                            <button type="button" className="btn-primary" onClick={() => { validateForm({ icc, clientName, clientLastname, address, dateActivation, datePortIn, function: 0 }) }} >VERIFICAR</button>
                        </div>
                    }
                </div>
            )}

            {activar && (
                <div className='form-row'>
                    <div className='col-12 my'>
                        <h3 className='subtitle-form'>Planes Disponibles</h3>
                    </div>
                    <div className='col-12 my d-flex justify-content-center'>
                        <button type="button" className="btn-warning" onClick={() => cancelOperation()} >CANCELAR</button>
                    </div>

                    <div className='col-12-cust'>
                        <div className='recharges-container'>
                            {rates.map((item) => (
                                <button className='recharge-item' key={item.id} onClick={() => { validateForm({ offerID: item.offerID, id: item.id, price: item.price, alta_offer_id: item.offer_id, function: 1, dateActivation, datePortIn }) }} >
                                    <div className='band-item'><img src={productsDictionary[product]} className="product-item" alt="logo" /></div>
                                    <h3 className='banner-card-title'>{item.name}</h3>
                                    <h3 className='banner-card-title-bold'>{item.price.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</h3>
                                </button>
                            ))}
                        </div>

                    </div>


                </div>
            )}

            {doneActivation && (
                <div className='col-12'>
                    <div className='ticket'>
                        <div className='ticket-body'>
                            <h2 className='title-ticket'>ACTIVACIÓN EXITOSA</h2>
                            <img src={check} className="check" alt="logo" />
                            <h3 className='label-ticket'>Fecha: {formatEDDay(effectiveDateShow)}/{formatEDMonth(effectiveDateShow)}/{formatEDYear(effectiveDateShow)}  Hora: {formatEDHour(effectiveDateShow)}:{formatEDMinute(effectiveDateShow)}</h3>
                            <h3 className='label-ticket'>No. Autorizacion: <span>{orderIDShow}</span></h3>
                            <h3 className='label-ticket'>Offer ID: <span>{offeringId}</span></h3>
                            <h3 className='label-ticket'>Puedes tomar captura de este ticket para cualquier duda o aclaración.</h3>
                            <h3 className='subtitle-ticket'>Monto del plan activado:</h3>
                        </div>
                        <div className='ticket-footer'>
                            <div className='ticket-top-left'></div>
                            <div className='ticket-top-right'></div>
                            <h1 className='ticket-price'>${parseFloat(amountShow).toFixed(2)}</h1>
                            <h3 className='subtitle-ticket'>No. Asignado: <span>{msisdn}</span></h3>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

export default NewLine
