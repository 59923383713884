import React, {useEffect, useState, useRef } from "react"
import { FaDownload } from "react-icons/fa";
import jsPDF from 'jspdf';
import { consultCdrs } from "../../api/consultCdrs"
import {PdfConsumption} from "../../helpers/PdfConsumption"
import Swal from 'sweetalert2';

export const DownloadConsums = ({type, phone, dateStart, dateEnd}) => {
  const [Consumos, setConsumos] = useState([]);  

  function searchConsumo() {
    consultCdrs(type, phone, dateStart, dateEnd).then((res) => {
        setConsumos(res)
    })
    .catch((e) => {
        console.log(e.message)
    })
  } 

  const handleGeneratePdf = async () => {
    Swal.fire({
        title: 'Consultando',
        html: ' Procesando...',
        didOpen: () => {
            Swal.showLoading();
        }
    });
    await searchConsumo()
    await Swal.close();
    const pdfHtml = await PdfConsumption(Consumos, phone)
    var doc = new jsPDF('l', 'px', 'a1');

    doc.html(pdfHtml, {
			async callback(doc) {
				await doc.save('document');
			},
		});
	};

  return (
    <div className="consums-download_container">
        <button className="consums-download_btn" onClick={handleGeneratePdf}>
            <FaDownload />
            <span>Descargar PDF</span>
        </button>
    </div>
  );
};