import React from 'react'
import { Navbar } from '../../components/Navbar'

export const Wrappers = ({ children, typeNavBar, isFc }) => {
  let mainClasses = '';
  if (isFc) {
    mainClasses += 'mainResp';
  }
  return (
    <>
      <Navbar
        typeNavBar={typeNavBar}
      />
      <div id="main" className={mainClasses}>
        {children}
      </div>
    </>
  )
}
