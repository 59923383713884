import React, {useEffect, useState} from "react"
import { FaCalendarAlt } from "react-icons/fa";
import { consultCdrs } from "../../api/consultCdrs"
import Swal from 'sweetalert2';

export const ListConsums = ({type, phone, dateStart, dateEnd}) => {
  const [Consumos, setConsumos] = useState([]);    

  let consum = [];
  
  const consultConsums = async () => {
    Swal.fire({
        title: 'Consultando',
        html: ' Procesando...',
        didOpen: () => {
            Swal.showLoading();
        }
    });
    const response = await consultCdrs(type, phone, dateStart, dateEnd);
    await Swal.close();
    {for (let i = 0; i < response.length; i++) {
      consum.push(
        <div className="consums-list_element"> 
          <div className="consums-list_calendar">
            <FaCalendarAlt />
          </div>
          <div className="consums-list_desc">
            <span> {response[i].START_DATE} </span>
            <span> {parseFloat(response[i].consumos).toFixed(2)} {response[i].UNIDAD} </span>
          </div>
        </div>
      )
    }}
    setConsumos(consum)
  };

  // useEffect(() => {
  //   consultConsums(consum)
  // }, [type, phone, dateStart, dateEnd])

  return (
    <div className='consums-list_container'>
      <button className="consums-list_btn" onClick={()=>consultConsums()}>Consultar</button>

      <div className="consums-list_content">
        {Consumos}
      </div>
    </div>
  );
};