import React, { useEffect, useState } from 'react'
import '../../styles/Clients.css'
import { InputsForms } from './InputsForms'
import { useNavigate, NavLink } from 'react-router-dom'
import { login } from '../helpers/apiLogin'
import Loader from "react-js-loader";


export const ContentLogin = () => {
    
   const [cellphone, setCellphone] = useState('')
   const [password, setPassword] = useState('')
   const [notifyLogin, setNotifyLogin] = useState('')
   const [showNotifyDanger, setShowNotifyDanger] = useState(false)
   const [loader, setLoader] = useState(false)

    const navigate = useNavigate()
    useEffect(() => {
        localStorage.removeItem("client_id");
        localStorage.removeItem("devices");
    }, [])

    let data = { cellphone, password };
    const onLogin = async () =>{
        if (Object.values(data).includes('')) {
            setNotifyLogin("Completa todos los campos del formulario.");
            setShowNotifyDanger(true)
            setLoader(false)

            return false;
        }else{
            setShowNotifyDanger(false)
            setLoader(true)
            const data = await login({cellphone, password})
            if (data.http_code == 200) {
                localStorage.setItem("client_id", data.userId);
                localStorage.setItem("devices", JSON.stringify(data.devices));
                navigate('/home-clients',{
                replace: true
                })
            }else{
                setNotifyLogin("Datos erroneos.");
                setShowNotifyDanger(true)
            }
        }
    }
  return (
    <div className='content-options'>
        <div className="m-3 logo" style={{'flex':1}}>
        </div>
        <div className="content-options" style={{'flex':1}}>
            {/* <h2 className='p-white'>Hola, bienvenido</h2> */}
            <h2 className='p-white'>Iniciar sesión</h2>
            <span className='p-white'>Estamos contentos de verte.</span>
        </div>
        <div className="mb-2 inputs-login" style={{'flex':1}}>
            <form >
                <div class="form-group">
                    <label className='p-white' for="exampleInputEmail1">Número</label>
                    <input type="text" style={{'border-radius': '30px'}} name='cellphone' maxLength={10} value={cellphone} onChange={(event)=>setCellphone(event.target.value)} class="form-control form-control-lg" id="exampleInputPassword1" placeholder="2300000000"/>
                </div>
                <div class="form-group">
                    <label className='p-white' for="exampleInputEmail1">Contraseña</label>
                    <input type="password" style={{'border-radius': '30px'}} name='password' value={password} onChange={(event) => setPassword(event.target.value)} class="form-control form-control-lg" id="exampleInputPassword1" placeholder="*********"/>
                </div>
                {showNotifyDanger && (
                    <label className="label-danger">{notifyLogin}</label>
                )}

                {
                    loader && (
                        <Loader type={'spinner-circle'} size={100}></Loader>
                    )
                }
            </form>
        </div>
        <div className="" style={{'flex':1}}>
            <button className='btn btn-primary btn-lg btn-block' style={{'border-radius': '30px'}} onClick={onLogin}>Iniciar sesión</button>
            <p className='p-white mt-2'>¿Aún no tienes una cuenta? <NavLink to="/register-clients">Registrarse </NavLink></p>
        </div>
    </div>
  )
}
