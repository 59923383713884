import React, { useEffect, useState } from 'react'
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { MdOutlineLocalGroceryStore } from "react-icons/md";
import { CircularProgress } from '../components';
import { uf } from '../helpers/uf';
import { Link, useLocation } from "react-router-dom"
import Loader from '../../components/Loader';

export const Uf = ({ renderNavBarDealers }) => {
    const currentPathLocal = window.location.pathname;
    const location = useLocation()
    const number = new URLSearchParams(location.search)
    const [planExp, setPlanExp] = useState('')
    const [planInicio, setPlanInicio] = useState('')
    const [datosTotal, setDatosTotal] = useState('')
    const [datosConsumidos, setDatosConsumidos] = useState('')
    const [datosRestantes, setDatosRestantes] = useState('')
    const [datos, setDatos] = useState([])
    const [status, setStatus] = useState('')
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setLoader(true)
        getUf()
        renderNavBarDealers(currentPathLocal);
    }, [])

    const getUf = async () => {
        const data = await uf(number.get("number"))
        setLoader(false)
        setStatus(data.status)
        if (data.expireRateDB) {
            setPlanExp(data.expireRateDB)
        } else {
            setPlanExp(data.finPlan)
        }
        setPlanInicio(data.inicioPlan)
        setDatosTotal(data.datosTotal)
        setDatosConsumidos(data.datosConsumido)
        setDatosRestantes(data.datosRestantes)
        setDatos(data.consultUF.freeUnits.nacionales)
    }
    return (
        <>
            <div className='title-ufInfo'>
                <div>
                    <span className='size-status'>Status: <span className='bold-status'>{status}</span></span>
                </div>
                <div className='date-expirate'>
                    <div className="consumosInFin">
                        <p className='color-title-consumo'>Contratado</p>
                        <p>{datosTotal} MB</p>
                    </div>
                    <div className="consumosInFin">
                        <p className='color-title-consumo'>Consumo</p>
                        <p>{datosConsumidos} MB</p>
                    </div>
                    <div className="consumosInFin">
                        <p className='color-title-consumo'>Restante</p>
                        <p>{datosRestantes} MB</p>
                    </div>
                </div>
            </div>
            <div className='title-consumos'>
                <h1>Consumos</h1>
            </div>
            <div className={`${loader ? '': 'chart-content'}`}>
                {loader ? (
                    <div style={{justifyItems:'center'}}>
                        <Loader title={"Cargando..."}/>
                    </div>
                        ) :
                  datos.length > 0 ?
                    datos.map(function (data) {
                        return (
                            <CircularProgress name={data.name} freePercentage={data.freePercentage}></CircularProgress>
                        )
                    })
                     : <div style={{justifyContent: 'center'}}>
                         <h2 style={{color: 'red', textAlign: 'center'}}>No cuenta con un plan.</h2>
                     </div> 
                        
                }
            </div>
            <div className='btn-navigationConsumoInfo' >
                <div className="">
                    <Link className="btn-navigation btn_info-home" to={'/home-clients'}>

                        <MdOutlineArrowBackIosNew color='white' />
                        <span style={{ color: 'white' }}>Regresar</span>
                    </Link>
                </div>
                <div className="">
                    <Link className="btn-navigation btn_info-recharge" to={`/recarga?number=${number}`}>

                        <MdOutlineLocalGroceryStore color='white' />
                        <span style={{ color: 'white' }}>Recargar</span>
                    </Link>

                </div>
            </div>
        </>
    )
}
