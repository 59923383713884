export const login = async({cellphone, password}) => {
    // const data = {cellphone, password}
    // return data
    try {
        const response = await fetch('https://apps-ws.spot1.mx/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                cellphone,
                password,
            })
        }) 
        // return response.json()
        const {userId, message, http_code, devices} = await response.json()
       
        const responseApi = {
            userId,
            message,
            http_code,
            devices
        }
        return responseApi

    }catch(error){
        console.log(error)
    }
}