import React, { useState, useEffect } from 'react'
import '../../../styles/Generic.css'
import '../../../styles/Modal.css'
import logo from '../../../img/logo-white.png'
import check from '../../../img/check.png'
import Swal from 'sweetalert2';
import { formatEDDay, formatEDMonth, formatEDYear, formatEDHour, formatEDMinute } from '../../../helpers'

const productsDictionary = {
    MOV: require('../../../img/mov-short.png'),
}

const Recharge = ({ setModalIsOpen, msisdn, offeringId, accessToken, userID, userName }) => {

    const [rates, setRates] = useState([]);
    const [ratesBool, setRatesBool] = useState(true);
    const [number_id, setNumberID] = useState(0)
    const [saldo, setSaldo] = useState(0)
    const [salesforce, setSalesforce] = useState(0)

    const [doingRecharge, setDoingRecharge] = useState(false);
    const [doneRecharge, setDoneRecharge] = useState(false);
    const [orderIDShow, setOrderIDShow] = useState(0)
    const [amountShow, setAmountShow] = useState(0)
    const [effectiveDateShow, setEffectiveDateShow] = useState('')

    useEffect(() => {
        const saldoStrg = localStorage.getItem('saldo');
        const salesforceStrg = localStorage.getItem('salesforce');
        setSaldo(saldoStrg)
        setSalesforce(salesforceStrg)
        getRates();
    }, [])

    const closeModal = () => {
        setModalIsOpen(false);
    }

    const getRates = async () => {
        console.log(offeringId, "OFFER ID")
        try {
            const response = await fetch('https://apps-ws.spot1.mx/get-rates-pos', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    msisdn,
                    offeringId
                })
            })

            const responseJson = await response.json();

            if (response.status == 200) {
                setRates(responseJson.rates)
                setNumberID(responseJson.number_id)

                console.log(responseJson, " | RESPONSE GET RATES | LINE 65");
                // console.log(responseJson.rates," NUMBER ID | LINE 66");
                // console.log(responseJson," RES JSON | LINE 68");
                setRatesBool(false);
            } else {
                // Alert.alert('Woops!', responseJson.message)
            }

            //   console.log(responseJson, 'RATES')
        } catch (error) {
            console.log(error, 'ERROR EN GET RATES')
        }
    }

    const getRateChoosed = item => {
        const { id, name, offerID, price, product, type_product, offer_id } = item;
        console.log(item, " | ITEM RATE | LINE 67");

        if (price > saldo && salesforce == 0) {
            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: 'No puedes realizar este movimiento, al parecer no cuentas con saldo suficiente, intenta con una recarga con un precio diferente.',
                showConfirmButton: false,
                timer: 1500
            })
            return false;
        }

        console.log("SE PUEDE REALIZAR LA RECARGA", " | ITEM RATE | LINE 80");

        Swal.fire({
            title: 'ATENCION!!',
            text: "Está seguro de realizar una recarga de $" + parseFloat(price) + "?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, continuar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Realizando recarga.',
                    html: 'Espera, no te desesperes Pérez...',
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                doRecharge({ id, name, offerID, price, product, type_product, offer_id })
            }
        });

    }

    const doRecharge = async data => {
        const { id, name, offerID, price, product, type_product, offer_id } = data;
        const offerIDRequest = offerID.toString();
        console.log(offerIDRequest, " | LINE 98")
        // setDoingRecharge(true)
        try {
            const response = await fetch('https://apps-ws.spot1.mx/api/api-router', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    api: "product_purchase",
                    data_request: {
                        msisdn: msisdn,
                        offerID: offerIDRequest,
                        accessToken: accessToken,
                    }
                })
            })

            const responseJson = await response.json();
            console.log(responseJson, " | RESPONSE JSON LINE 117");


            if (response.status == 200) {

                const { effectiveDate, msisdn, order } = responseJson;
                const orderID = order.id;
                Swal.fire({
                    icon: 'success',
                    title: 'Recarga realizada con éxito!!',
                    // text: "Order ID: " + orderID + ' - Número: ' + msisdn + ' - Fecha Efectiva: ' + effectiveDate,
                    showConfirmButton: false,
                    timer: 1500
                })
                saveRecharge({ id, name, offerID, price, product, type_product, effectiveDate, msisdn, orderID, offer_id });
                console.log('RECARGA EXITOSA')
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Woops!!',
                    text: responseJson.description,
                    showConfirmButton: false,
                    timer: 1500
                })
                console.log(responseJson)
            }

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: error,
                showConfirmButton: false,
                timer: 1500
            })
            console.log(error)
        }
    }

    const saveRecharge = async data => {
        const { id, name, offerID, price, product, type_product, effectiveDate, msisdn, orderID, offer_id } = data;
        console.log(data, 'SAVE RECHARGE GOING');
        let saldoUserR = parseFloat(saldo) - parseFloat(price);
        let request = {
            userID,
            userName,
            rate_id: id,
            rate_name: name,
            price,
            product,
            type_product,
            effectiveDate,
            msisdn,
            orderID,
            saldo: saldoUserR,
            salesforce,
            number_id,
            offer_id
        };
        console.log(request, ' REQUEST | LINE 156 | Recharge.jsx')
        try {
            const response = await fetch('https://apps-ws.spot1.mx/save-recharge-pos', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            })

            const responseJson = await response.json();
            console.log(responseJson, 'SAVE RECHARGE DID')

            if (response.status == 200) {
                // Alert.alert('Exito!!',responseJson.message)
                if (salesforce == 0) {
                    setSaldo(saldoUserR)
                    localStorage.setItem("saldo", saldoUserR);
                } else {
                    setSaldo(saldo)
                    localStorage.setItem("saldo", saldo);
                }

                setOrderIDShow(orderID)
                setAmountShow(price)
                setEffectiveDateShow(effectiveDate)
                setDoingRecharge(false)
                setDoneRecharge(true)
                setRates([])
            } else {
                // Alert.alert('Woops!', responseJson.message)
                Swal.fire({
                    icon: 'warning',
                    title: 'Woops!!',
                    text: responseJson.message
                })
            }

            //   console.log(responseJson, 'RATES')
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Woops!!',
                text: error
            })
            console.log(error)
        }
    }

    return (
        <div className='col-12'>
            <div className='col-12 banner-container'>
                <div className='banner-modal'>
                    <div>
                        <img src={logo} className="logo" alt="logo" />
                    </div>
                    <div>
                        <h1 className='title-banner-modal'>RECARGA</h1>
                    </div>
                    <div>
                        <button className='btn-close' type='button' onClick={closeModal}>X</button>
                    </div>

                </div>
            </div>
            <div className='col-12'>
                <div className='banner-card'>
                    <h3 className='banner-card-title'>Saldo Disponible</h3>
                    <h1 className='banner-card-title-h1'>${parseFloat(saldo).toFixed(2)}</h1>
                    <h3 className='banner-card-title'>{msisdn}</h3>
                </div>
            </div>

            {doneRecharge && (
                <div className='col-12'>
                    <div className='ticket'>
                        <div className='ticket-body'>
                            <h2 className='title-ticket'>RECARGA EXITOSA</h2>
                            <img src={check} className="check" alt="logo" />
                            <h3 className='label-ticket'>Fecha: {formatEDDay(effectiveDateShow)}/{formatEDMonth(effectiveDateShow)}/{formatEDYear(effectiveDateShow)}  Hora: {formatEDHour(effectiveDateShow)}:{formatEDMinute(effectiveDateShow)}</h3>
                            <h3 className='label-ticket'>No. Autorizacion: <span>{orderIDShow}</span></h3>
                            <h3 className='label-ticket'>Puedes tomar captura de este ticket para cualquier duda o aclaración.</h3>
                            <h3 className='subtitle-ticket'>Monto de la recarga:</h3>
                        </div>
                        <div className='ticket-footer'>
                            <div className='ticket-top-left'></div>
                            <div className='ticket-top-right'></div>
                            <h1 className='ticket-price'>${parseFloat(amountShow).toFixed(2)}</h1>
                            <h3 className='subtitle-ticket'>Telefono: <span>{msisdn}</span></h3>
                        </div>
                    </div>


                </div>
            )}

            {rates.length === 0 ?
                <div className='col-12'>
                    {ratesBool && (
                        <div className='recharges-container'>
                            <h2 className='title-ticket'>No hay recargas por mostrar...</h2>
                        </div>
                    )}
                </div>
                :
                <div className='col-12'>
                    <div className='recharges-container'>
                        {rates.map((item) => (
                            <button className='recharge-item' key={item.id} onClick={() => getRateChoosed(item)} >
                                <div className='band-item'><img src={productsDictionary[item.product]} className="product-item" alt="logo" /></div>
                                <h3 className='banner-card-title'>{item.name}</h3>
                                <h3 className='banner-card-title-bold'>{item.price.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</h3>
                            </button>
                        ))}
                    </div>

                </div>

            }




        </div>
    )
}

export default Recharge