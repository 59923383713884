import React from 'react'
import { CircularProgressbarWithChildren, buildStyles } from 
'react-circular-progressbar';
import RadialSeparators from "./RadialSeparators";
import "../../styles/circularProgress.css"

export const CircularProgress = ({name, typeProduct, freePercentage}) => {
  return (
    <div style={{ width: 200, height: 200, marginBottom: '100px', marginLeft: '70px'}}>
        <CircularProgressbarWithChildren value={freePercentage}
            text={`${freePercentage}%`}
            strokeWidth={10}
            styles={buildStyles({
            strokeLinecap: "butt",
            pathColor: "turquoise",
        })} >
        <RadialSeparators
            count={12}
            style={{
                background: "#fff",
                width: "2px",
                // This needs to be equal to props.strokeWidth
                height: `${10}%`
            }}
        />
        </CircularProgressbarWithChildren>
        <h2>{name}</h2>
    </div>
  )
}
