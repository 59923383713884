import React, { useEffect, useState } from 'react'
import { Routes, Route } from "react-router-dom"
import { Navbar } from "./Navbar"
import { Inicio } from "../Inicio"
import { Home } from "../Dealers/Home"
import { Login } from "../Dealers/Login"
import { HomeClients, LoginPage, Registe, Uf, Profile } from '../clients/pages/'
import { Consumos } from "../clients/pages/Consumos"
import { Recarga } from "../clients/pages/Recarga"
import { Numbers } from "../clients/pages/Numbers"
import { Wrappers } from '../clients/components/Wrappers'
// import { PrivateRoute } from "./PrivateRoute"

export const AppRouter = () => {

    const [logged, setLogged] = useState(false)
    const [user_name, setUsername] = useState('')
    const [user_lastname, setUserLastname] = useState('')

    // VARIABLES PARA LA CARGA DE NAVBAR SEGUN EL PORTAL AL QUE ACCEDAN
    const [typeNavBar, setTypeNavBar] = useState('principal')
    const dealersRoutes = ['/login', '/home'];
    // const clientsRoutes = ['/login-clients', '/home-clients'];
    const clientsRoutes = ['/login-clients', '/register-clients', '/home-clients', '/profile', '/consumos', '/uf', '/recarga', '/numbers'];
    const simpleRoutes = ['/'];
    const isFc = true;

    const renderNavBarDealers = (currentPathLocal) => {
        console.log("| VALIDATION ROUTES IN APP ROUTER JSX |");
        if (dealersRoutes.includes(currentPathLocal)) {
            setTypeNavBar('dealers');
            return 0;
        } else if (clientsRoutes.includes(currentPathLocal)) {
            if (currentPathLocal != "/login-clients" && currentPathLocal != "/register-clients") {
                setTypeNavBar('clients');
                return 0;
            } else {
                setTypeNavBar('');
                return 0;
            }

        } else {
            setTypeNavBar('principal');
            return 0;
        }
    };

    return (
        <>
            <Routes>
                <Route path="/" element={
                    <Navbar logged={logged} setLogged={setLogged}
                        user_name={user_name} setUsername={setUsername}
                        user_lastname={user_lastname} setUserLastname={setUserLastname}
                        typeNavBar={typeNavBar} renderNavBarDealers={renderNavBarDealers}
                    />
                }>
                    <Route />

                    {/* AQUI EMPIEZAN TODAS LAS RUTAS DIFERENTES A LA RUTA PRINCIPAL */}
                    <Route index element={
                        <Inicio logged={logged} setLogged={setLogged}
                            user_name={user_name} setUsername={setUsername}
                            user_lastname={user_lastname} setUserLastname={setUserLastname}
                            renderNavBarDealers={renderNavBarDealers}
                        />
                    } />

                    <Route path="/home" element={<Home logged={logged} setLogged={setLogged}
                        user_name={user_name} setUsername={setUsername}
                        user_lastname={user_lastname} setUserLastname={setUserLastname}
                        renderNavBarDealers={renderNavBarDealers} />}
                    />

                    <Route path="/login" element={<Login renderNavBarDealers={renderNavBarDealers} />} />
                    {/* AQUI TERMINAN TODAS LAS RUTAS DIFERENTES A LA RUTA PRINCIPAL */}

                    <Route path='/profile' element={
                        <Wrappers>
                            <Profile
                                renderNavBarDealers={renderNavBarDealers}
                                typeNavBar={typeNavBar}
                            />
                        </Wrappers>
                    } />

                    <Route path='/login-clients' element={
                        <LoginPage renderNavBarDealers={renderNavBarDealers} />
                    } />

                    <Route path='/home-clients' element={
                        <Wrappers>
                            <HomeClients renderNavBarDealers={renderNavBarDealers} />
                        </Wrappers>
                    } />

                    <Route path='/register-clients' element={
                        <Registe renderNavBarDealers={renderNavBarDealers} />
                    } />

                    <Route path='/uf' element={
                        <Wrappers
                            isFc={isFc}
                        >
                            <Uf renderNavBarDealers={renderNavBarDealers} />
                        </Wrappers>
                    } />

                    <Route path='/recarga' element={
                        <Wrappers>
                            <Recarga renderNavBarDealers={renderNavBarDealers} />
                        </Wrappers>
                    } />

                    <Route path='/consumos' element={
                        <Wrappers>
                            <Consumos renderNavBarDealers={renderNavBarDealers} />
                        </Wrappers>
                    } />

                    <Route path='/numbers' element={
                        <Wrappers>
                            <Numbers renderNavBarDealers={renderNavBarDealers} />
                        </Wrappers>
                    } />
                </Route>
            </Routes>
        </>
    )
}