export const register = async({cellphone, password, confirmpassword}) => {
    // const data = {cellphone, password}
    // return data
    try {
        const response = await fetch('https://apps-ws-test.spot1.mx/signIn', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                cellphone,
                password,
                passwordConfirm:confirmpassword
            })
        }) 
        let data = await response.json()
        return data
    } catch (error) {
        console.log(error)
    }
}