import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import logo from '../img/logo-white.png';
import logoNav from '../img/Logo-spotuno.png';
import logoutBtn from '../img/power-blue.png';
import menu from '../img/menu.jpg';
import logoPrincipal from '../img/Logo-spotuno.png';
import Swal from 'sweetalert2';
import '../styles/Navbar.css';

import { IoHomeOutline } from "react-icons/io5";
import { TfiReload, TfiStatsUp } from "react-icons/tfi";
import { LuScanFace } from "react-icons/lu";
import { MdOutlinePrivacyTip } from "react-icons/md";

export const Navbar = ({ logged, setLogged, user_name, user_lastname, setUsername, setUserLastname, typeNavBar }) => {
    const navigate = useNavigate();
    const [sidebarActive, setSidebarActive] = useState(false)

    const [screenMeasurement, setsCreenMeasurement] = useState(window.innerWidth);

    // const screenMeasurement = window.innerWidth;

    const [mostrarBarra, setMostrarBarra] = useState(false);

    useEffect(() => {

        const login = localStorage.getItem('login');
        const name = localStorage.getItem('name');
        const lastname = localStorage.getItem('lastname');
        const user_id = localStorage.getItem('user_id');

        if (login == null && name == null && lastname == null && user_id == null) {
            console.log('Sesion NO iniciada... | Navbar.js LINE 21');

        } else {
            setUsername(name)
            setUserLastname(lastname)
            setLogged(true)
        }
        console.log("LOG IN NAVBAR JSX");
        // renderNavBarDealers();

        const handleResize = () => {
            setsCreenMeasurement(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        window.addEventListener("scroll", manejarScroll);

        return () => {
            window.removeEventListener("resize", handleResize);

            window.removeEventListener("scroll", manejarScroll)
        };

    }, []);

    const pressToggle = () => {
        console.log("Clicked");
        setSidebarActive(!sidebarActive);
    }

    const logout = () => {

        Swal.fire({
            title: '¿Está seguro de cerrar sesión?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, continuar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('login');
                localStorage.removeItem('name');
                localStorage.removeItem('lastname');
                localStorage.removeItem('user_id');
                localStorage.removeItem('saldo');
                localStorage.removeItem('salesforce');
                setLogged(false)
                navigate('/login');
            }
        });

    }

    const openNav = () => {
        if (screenMeasurement <= 767) {
            document.getElementById("sideNavigation").style.width = "100%";
            document.getElementById("main").style.marginLeft = "100%";
        } else {
            document.getElementById("sideNavigation").style.width = "20%";
            document.getElementById("main").style.marginLeft = "20%";
        }
    }

    function closeNav() {
        document.getElementById("sideNavigation").style.width = "0";
        document.getElementById("main").style.marginLeft = "0";
    }

    const manejarScroll = () => {
        if (window.pageYOffset > 20) {
            setMostrarBarra(true);
        } else {
            setMostrarBarra(false);
        }
    }

    const barraEstilos = {
        marginTop: mostrarBarra ? "0" : "",
    };

    return (
        <>

            {typeNavBar == 'dealers' && (
                <div className="navbar-container">
                    <nav className='navbar-items'>
                        <div><img src={logo} className="logo" alt="logo" /></div>

                        {logged && (
                            <div>
                                <Link className='nav-link' to="home">Home</Link>
                            </div>
                        )}

                        {logged ? (
                            <div className="user">
                                <span className="username">{user_name} {user_lastname}</span>
                                <button className='btn-logout' onClick={logout}>
                                    <img src={logoutBtn} className="logout-img" alt="logo" />
                                </button>
                            </div>
                        ) : (
                            <div>
                                <Link className='nav-link' to="login">Login</Link>
                            </div>
                        )
                        }


                        <div id="sidebar" className={sidebarActive ? 'sidebar sidebar-active' : 'sidebar'}>
                            <button type="button" className={sidebarActive ? 'toggle-btn toggle-btn-active' : 'toggle-btn'} onClick={() => pressToggle()}>
                                <span>&#9776;</span>
                            </button>

                            {logged ? (
                                <ul>
                                    <li><img src={logoNav} className="logo" alt="logo" /></li>
                                    <li><Link className='nav-link-responsive' to="home" onClick={() => pressToggle()}>Home</Link></li>
                                </ul>
                            ) : (
                                <ul>
                                    <li><img src={logoNav} className="logo" alt="logo" /></li>
                                </ul>
                            )}

                            {logged && (
                                <div className="user-responsive">
                                    <span className="username">{user_name} {user_lastname}</span>
                                    <button className='btn-logout' onClick={() => { logout(); pressToggle(); }}>
                                        <img src={logoutBtn} className="logout-img" alt="logo" />
                                    </button>
                                </div>
                            )}


                        </div>

                    </nav>
                </div>
            )}

            {typeNavBar == 'principal' && (
                <div className="navbar-container-principal">
                    <nav className='navbar-items'>
                        <div><img src={logoPrincipal} className="logo" alt="logo" /></div>
                        <div>
                            <Link className='nav-link-principal' to="login">Distribuidor</Link>
                        </div>


                        {/* <div id="sidebar" className={sidebarActive ? 'sidebar sidebar-active' : 'sidebar'}>
                            <button type="button" className={sidebarActive ? 'toggle-btn toggle-btn-active' : 'toggle-btn'} onClick={() => pressToggle()}>
                                <span>&#9776;</span>
                            </button>

                            <ul>
                                <li><img src={logoNav} className="logo" alt="logo" /></li>
                                <li><Link className='nav-link-responsive' to="login" onClick={() => pressToggle()}>Login</Link></li>
                            </ul>
                        </div> */}

                    </nav>
                </div>
            )}

            {typeNavBar == 'clients' && (
                <>
                    <nav id="sideNavigation" className="sidenav">
                        <img src={menu} className="img-menu" alt="logo" />
                        <button class="closebtn" onClick={closeNav}>&times;</button>
                        <div className="mt-3 pa-enlaces li">
                            <Link to={"home-clients"}><IoHomeOutline /> Inicio</Link>
                            <Link to={"consumos"}><TfiStatsUp /> Estado de cuenta</Link>
                            <Link to={"numbers"}><TfiReload /> Recarga</Link>
                            {/* <a href="#"><LuScanFace/>Mi perfil</a> */}
                            <Link to={"profile"}><LuScanFace /> Mi perfil</Link>
                            <a href="https://spot1mobile.com/aviso-privacidad" target="_blank"><MdOutlinePrivacyTip /> Aviso de privacidad</a>
                        </div>
                        {/* <hr className='hr'/>
                        <div className="mt-2 pa-enlaces li">
                            <a href="#"><CiClock2/> Opción 1</a>
                            <a href="#"><GoBroadcast/> Opción 2</a>
                            <a href="#"><GiPadlock/> Cambiar contraseña</a>
                        </div> */}
                    </nav>

                    <nav className="topnav barra" style={barraEstilos}>
                        <button onClick={openNav} className="openbtn">
                            <svg width="30" height="30" id="icoOpen">
                                <path d="M0,5 30,5" stroke="#000" stroke-width="5" />
                                <path d="M0,14 30,14" stroke="#000" stroke-width="5" />
                                <path d="M0,23 30,23" stroke="#000" stroke-width="5" />
                            </svg>
                        </button>
                    </nav>
                </>
            )}

            <Outlet />
        </>
    );
};