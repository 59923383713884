import React, { useEffect } from 'react'
import { ContentLogin, ImageBackground } from '../components'
import '../../styles/Clients.css'

export const LoginPage = ({renderNavBarDealers}) => {
  const currentPathLocal = window.location.pathname;

  useEffect(() => {
    renderNavBarDealers(currentPathLocal);
  }, [])
  
  return (
    <>
      <div className="contenedor">
        <div className='contenedor-pre-login'>
          <ContentLogin/>
        </div>
        <div className='bg-pre-login'>
          <ImageBackground/>
        </div>
      </div>
    </>
  )
}
