export const formatEDDay = effectiveDate => {
    return effectiveDate.substring(6, 8)
}

export const formatEDMonth = effectiveDate => {
    return effectiveDate.substring(4, 6)
}

export const formatEDYear = effectiveDate => {
    return effectiveDate.substring(0, 4)
}

export const formatEDHour = effectiveDate => {
    return effectiveDate.substring(8, 10)
}

export const formatEDMinute = effectiveDate => {
    return effectiveDate.substring(10, 12)
}