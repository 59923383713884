import React from 'react';
import '../styles/Loader.css'; // Importa estilos CSS para el spinner

const Loader = ({title}) => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
      <div className='loader-text'>{title}</div>
    </div>
  );
};

export default Loader;
